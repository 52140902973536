.App {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: "Lucida Console", "Courier New", monospace;
}

.Nav {
  display: flex;
  padding: 15px;
  background-color: gray;
  justify-content: space-evenly;
  background: rgb(34,193,195);
  background: linear-gradient(128deg, rgba(34,193,195,1) 0%, rgba(255,182,182,1) 100%);
  margin-bottom: 1px;
  color: white;
}

.content {
  margin: 0 0 0 20;
  height: calc(100vh - 70px);
  overflow-y: auto;
  flex: 1;
  display: flex;  
  flex-wrap: wrap;
  overflow-y: auto;
}

.NotNav {
  display: flex;  
  flex-wrap: wrap;
  overflow-y: auto;
}

.Sidebar {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f2f2f2;
  flex: 0 0 300px;
  background: rgb(34,193,195);
  background: linear-gradient(128deg, rgba(34,193,195,1) 0%, rgba(255,182,182,1) 100%);
}

.NotSidebar {
  margin: 0 0 0 20;
  height: calc(100vh - 50px);
  overflow-y: auto;
  flex: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.item-card {
  border-color: black;
  border-width: 2px;
  border-style: solid;
  margin: 5px;
  border-radius: 20px;
  width: 300px;
  height: 375px;
  background-color: #f2f2f2;
  opacity: 1;
  transition: opacity 0.5s ease;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


img { 
  height: 200px;
  width: 200px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  width: 300px;
  padding: 20px;
  border-radius: 4px;
}